import { Link } from "gatsby-plugin-intl"
import React, { useEffect, useRef, useState } from "react"
import { localized } from "../utils/localized"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"

const Tabs = ({ headers, currentIndex }) => {
  const [posX, setPosX] = useState(0)
  const [parentWidth, setParentWidth] = useState(null)
  const [childWidth, setChildWidth] = useState(null)
  const ref = useRef()
  const parentRef = useRef()
  const scrollLeft = () => {
    const amount = posX + 150 <= 0 ? 150 : -posX
    animate({
      timing: fraction => amount * fraction,
      draw: progress => setPosX(posX + progress),
      duration: 150,
    })
  }
  const scrollRight = () => {
    const maxMovement = childWidth - parentWidth
    let amount = 0
    if (childWidth && parentWidth)
      if (-posX < maxMovement) {
        if (maxMovement + posX > 150) {
          amount = 150
        } else {
          amount = maxMovement + posX
        }
      }

    animate({
      timing: fraction => -amount * fraction,
      draw: progress => setPosX(posX + progress),
      duration: 150,
    })
  }
  useEffect(() => {
    setParentWidth(parentRef?.current?.offsetWidth)
    setChildWidth(ref?.current?.scrollWidth)
  }, [])

  return (
    <div className="tabs margin-bottom-md">
      <FontAwesomeIcon
        className={`category-arrow left ${posX === 0 ? "disabled" : null}`}
        icon={faCaretLeft}
        onClick={scrollLeft}
        size="2x"
        color="var(--color-primary)"
      />
      <FontAwesomeIcon
        className={`category-arrow right ${
          -posX + parentWidth >= childWidth ? "disabled" : null
        }`}
        icon={faCaretRight}
        onClick={scrollRight}
        size="2x"
        color="var(--color-primary)"
      />
      <div className="category-wrapper" ref={parentRef}>
        <div
          className="tab-content"
          ref={ref}
          style={{ left: posX }}
          role="button"
        >
          {headers.map((item, index) => (
            <Link
              key={"cat_" + index}
              to={item.link}
              className={`tab-box ${
                index === currentIndex ? "tab-active" : ""
              }`}
              datatext={localized(item.text)}
            >
              <div className="tab-accent"></div>
              {localized(item.text)}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

const animate = ({ timing, draw, duration }) => {
  let start = performance.now()

  const animateLoop = time => {
    const durationFraction = Math.min(1, Math.max(0, (time - start) / duration))
    const progress = timing(durationFraction)
    draw(progress)
    if (durationFraction < 1) {
      requestAnimationFrame(animateLoop)
    }
  }

  requestAnimationFrame(animateLoop)
}

export default Tabs
