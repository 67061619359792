import React from "react"
import { Link } from "gatsby-plugin-intl"
import { buildImageObj } from "./lib/helpers"
import { imageUrlFor } from "./lib/image-url"

export default ({ slug, title, excerpt, mainImage }) => (
  <Link
    key={slug.current}
    to={slug.current === "quiz" ? `/quiz` : `/blog/${slug.current}`}
    style={{ color: "black", textDecoration: "none" }}
  >
    <div style={{ height: "100%" }} className="card--shadow">
      <header className="card__header">
        <div>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(600)
              .height(300)
              .auto("format")
              .url()}
            alt=""
          />
        </div>
      </header>
      <div className="card__content height-auto">
        <div className="card__labelSticker card__labelSticker--small" />
        <div className="text-component">
          <h3 className="card__title">{title}</h3>

          <p>
            {excerpt
              .split(" ")
              .splice(0, 25)
              .join(" ") + "..."}
          </p>
        </div>
      </div>
    </div>
  </Link>
)
