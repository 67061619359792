import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/blog/BlogCard"
import { Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { useIntl } from "react-intl"
import Slider from "react-slick"
import { imageUrlFor } from "../components/blog/lib/image-url"
import { buildImageObj } from "../components/blog/lib/helpers"
import { localized } from "../utils/localized"
import BlogCardV2 from "../components/blog/BlogCardV2"
import Tabs from "../components/Tabs"

const BlogIndex = props => {
  const intl = useIntl()
  const selectedCategory = props.pageContext.category
  const categories = props.data.allSanityCategory.edges.map(({ node }) => node)
  const currentIndex = !selectedCategory
    ? 0
    : categories.findIndex(
        item => `/${item.slug.current}/` === selectedCategory
      ) + 1
  const latest = props.data.latest.edges.map(({ node }) => node)

  let tabHeaders = categories.map(item => {
    return { text: item.title, link: "/blog/" + item.slug.current }
  })

  tabHeaders = [
    { text: { en: "All", vi: "Tất cả" }, link: "/blog" },
    ...tabHeaders,
  ]

  return (
    <Layout>
      <SEO title="Blog" />
      <section className="section overflow-visible">
        <div className="section__inner">
          <h2 className="text-xl margin-bottom-xs margin-top-lg">
            CoderSchool Blog
          </h2>
          <p className="margin-bottom-md">
            {intl.formatMessage({ id: "blog_subtext" })}
          </p>
          <div className="blog-section-title">
            {intl.formatMessage({ id: "latest_news" })}
          </div>
          <div className="blog-latest-news">
            <Slider {...settings}>
              {latest.map((item, index) => (
                <div
                  className="latest-card card--light-shadow margin-bottom-xs"
                  key={index}
                >
                  <div>
                    <Link to={`/blog/` + item.slug.current}>
                      <img
                        src={imageUrlFor(buildImageObj(item.mainImage))
                          .width(600)
                          .height(300)
                          .auto("format")
                          .url()}
                        alt={item.title}
                      />
                      <div className="blog-latest-title">{item.title}</div>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="flex justify-center"></div>
          <div className="blog-section-title margin-bottom-md">Blog</div>
          <Tabs headers={tabHeaders} currentIndex={currentIndex} />
          <div className="grid grid-gap-md grid-gap-xxl@md">
            {props.data.posts.edges.map((edge, index) => (
              <div className="col-6@sm col-4@md" key={edge.node.id}>
                <BlogCardV2 {...edge.node} />
              </div>
            ))}
          </div>
          <div className="flex flex-center margin-top-lg margin-bottom-md">
            <Link
              className={
                props.pageContext.previousPagePath
                  ? "blog-nav-button"
                  : "blog-nav-button disabled"
              }
              to={props.pageContext.previousPagePath}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="icon icon--xs" />
            </Link>

            <Link
              className={
                props.pageContext.nextPagePath
                  ? "blog-nav-button"
                  : "blog-nav-button disabled"
              }
              to={props.pageContext.nextPagePath}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="icon icon--xs"
              />
            </Link>
          </div>
          <div className="blog-section-title margin-bottom-xs">
            {intl.formatMessage({ id: "categories" })}
          </div>
          <div className="margin-bottom-xl">
            {categories.map((item, index) => (
              <Link to={"/blog/" + item.slug.current} key={item.id}>
                <div className="margin-bottom-xs">{localized(item.title)}</div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $category: String = "//") {
    allSanityCategory(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          title {
            en
            vi
          }
          slug {
            _key
            _type
            current
          }
        }
      }
    }
    latest: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 8
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImageFields
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        categories: { elemMatch: { slug: { current: { regex: $category } } } }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImageFields
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export default BlogIndex
